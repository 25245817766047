import { styled, Box } from "@mui/material";
import MyImage from "../../../assets/img3.jpg";
import {
  CustomButton,
  SectionWrapper,
  TitleWrapper,
} from "../../../styles/sectionStyles";
import { useEffect } from "react";
const AboutMeContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignSelf: "center",
  "& img": {
    width: 400,
    [theme.breakpoints.down("sm")]: {
      width: 350,
    },
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const DetailsWrapper = styled(Box)(({ theme }) => ({
  fontSize: 16,
  marginLeft: 15,
  "& .actions": {
    margin: "12px 0",
  },
  [theme.breakpoints.down("md")]: {
    margin: "10px 0 0 0",
  },
}));
export const AboutMe = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <SectionWrapper>
      <AboutMeContainer>
        <img src={MyImage} />
        <DetailsWrapper>
          <div>
          <h1 style={{marginTop: 0}}>
            Me chamo Vlad
          </h1>
         <p><strong>Devo estar perdido</strong> e procurando meu dono. Eu sou fujão e carente, por favor pode me ajudar?</p>
         </div>
          <div className="actions">
          <div>
             <p>Ligue para meu dono no número: (67) 9 9276-1610</p>
            <p>Ou fale com minha vovó no número: (67) 9 9121-4646</p>
            <p>Em último caso tente no número: (67) 9 9696-6486</p>
          </div>
          </div>
        </DetailsWrapper>
      </AboutMeContainer>
    </SectionWrapper>
  );
};
