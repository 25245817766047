import { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { SectionWrapper, TitleWrapper } from "../../../styles/sectionStyles";
import MyImage1 from "../../../assets/img1.jpg";
import MyImage2 from "../../../assets/img2.jpg";

export const PicturesContainer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <TitleWrapper style={{textAlign: "center"}}>Mais fotos minhas</TitleWrapper>
        <Grid container spacing={1}>
          <Grid item xs={6} md={6}>
            <img src={MyImage2} style={{width: 400, height: 400}}/>
          </Grid>
         
          <Grid item xs={6} md={6}>
          <img src={MyImage1} style={{width: 400, height: 400}}/>
          </Grid>
         
        </Grid>
        <Grid style={{textAlign: "center"}}>
            <p>Acha que sou o Vrad? Por favor ligue para meu dono</p>
          </Grid>
        </>
  );
};
