import { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { TitleWrapper } from "../../../styles/sectionStyles";
import MyImage1 from "../../../assets/img4.jpg";
import MyImage2 from "../../../assets/img5.jpg";
import MyImage3 from "../../../assets/img6.jpg";

export const SkillsContainer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <TitleWrapper style={{ textAlign: "center" }}>
        Só me entregue para as pessoas abaixo
      </TitleWrapper>

      <Grid
        container
        spacing={2}
        justifyContent="center" // Centraliza horizontalmente os itens
        style={{ minHeight: "100vh" }} // Define uma altura mínima para ocupar toda a tela
      >
        <Grid item xs={12} sm={3} md={3} lg={3}>
          <img
            src={MyImage2}
            style={{ width: "100%", maxWidth: "400px", height: "auto" }}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3}>
          <img
            src={MyImage1}
            style={{ width: "100%", maxWidth: "400px", height: "auto" }}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3}>
          <img
            src={MyImage3}
            style={{ width: "100%", maxWidth: "400px", height: "auto" }}
          />
        </Grid>
      </Grid>
    </>
  );
};
