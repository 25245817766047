import { ContentContainer } from "./components/Content";
import { Header } from "./components/Header";

function App() {
  return (
    <div className="app">
      <Header />
      <ContentContainer />
    
    </div>
  );
}

export default App;
