import Skills from "../../assets/skills.png";
import Person from "../../assets/hacker.png";
import { AboutMe } from "./AboutMe";
import { SkillsContainer } from "./Skills";
import { PicturesContainer } from "./Pictures";


interface ISectionImages {
  [key: string]: string | undefined;
}
interface ISectionsInfo {
  [key: string]: any | undefined;
}

export const sectionsInfo: ISectionsInfo = {
  aboutMe: { src: Person, component: <AboutMe /> },
  skills: { src: Skills, component: <SkillsContainer /> },
  skills1: { src: Skills, component: <PicturesContainer /> },
};
export const sectionImages: ISectionImages = {
  aboutMe: Person,
  skills: Skills,
  skills1: Skills,
};
